import React from 'react'
import { BsTwitter } from 'react-icons/bs'
import { FaGithub, FaLinkedinIn } from 'react-icons/fa'

const SocialMedia = () => {
  return (
    <div className='app__social'>
        <div>
            <a href='https://github.com/PranavKale03'>
              <FaGithub />
            </a>
        </div>
        <div>
          <a href='https://www.linkedin.com/in/kale-pranav/'>
            <FaLinkedinIn />
          </a>
        </div>
        <div>
          <a href='https://twitter.com/PranavKale03'>
            <BsTwitter />
          </a>
        </div>
    </div>
  )
}

export default SocialMedia